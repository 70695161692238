import React, { useMemo, useState } from "react";
import ModalProduct from "./ModalProduct/index";
import { useDispatch } from "react-redux";
import {
  CLOSE_MODAL_AUTH,
  MODAL_TOGGLE_PRODUCT,
  MODAL_TOGGLE,
  MODAL_CHANGE_ORDER,
  CLOSE_MODAL_BUDGET_ALLOCATED,
  CLOSE_MODAL_STRIPE,
  OPEN_MODAL_STRIPE,
} from "../../../store/Modal/Modal.constant";
import "./style.scss";
import ModalAuth from "./ModalAuth";
import ModalReplaced from "./ModalReplaced";
import ModalOrderSuccess from "./ModalOrderSuccess";
import ModalConfirmation from "./ModalСonfirmation";
import ModalShareLink from "./ModalShareLink";
import ModalChangeOrder from "./ModalChangeOrder";
import ModalOrderEnd from "./ModalOrderEnd";
import ModalEditProfile from "./ModalEditProfile";
import ModalBudgetAllocate from "./ModalBudgetAllocate";
import ModalStripeForm from "./ModalStripeForm";
import {
  CLIENT_SECRET_EXCEEDED_AMOUNT,
  MAKE_ORDER,
} from "../../../store/Products/Products.constant";

const Modals = (props) => {
  const [clientSecret, setClientSecret] = useState(null);
  const {
    common,
    slug_uuid,
    products,
    form,
    modals,
    balanceTopUpAmount,
    calculateExceededBudget,
    hideEdit,
  } = props;
  const settings = common.settings?.[slug_uuid];
  const groupOrders = common?.groupOrders?.[slug_uuid];
  const {
    member_id,
    group_order_id,
    id: group_order_member_id,
  } = groupOrders?.group_order_member || {};
  const product = modals?.modalProduct;
  const budget = common.settings?.[slug_uuid]?.budget;
  const dispatch = useDispatch();
  const compilingProductToOrder = (myProducts, auth) => {
    let group_order_items = [];
    myProducts.forEach((item) => {
      let quantity = 0;
      let newMappings = {};
      Object.keys(item.menu_items_mapping).forEach((key) => {
        quantity += item.menu_items_mapping[key].quantity;
        if (item.menu_items_mapping[key].quantity !== 0) {
          newMappings[key] = item.menu_items_mapping[key];
        }
      });
      group_order_items.push({
        group_order_member_id: member_id,
        group_order_id,
        menu_id: item.id,
        menu_price: item.price_cents,
        quantity: item.quantity || quantity,
        menu_item_mappings: newMappings,
        sub_total: item.total_price_cents,
        special_instruction: item.special_instruction,
      });
    });
    return group_order_items;
  };
  const extractedExceededPrice = useMemo(() => {
    if (product?.total_price_cents > budget + balanceTopUpAmount) {
      return Math.abs(calculateExceededBudget);
    }
    return 0;
  }, [
    product?.total_price_cents,
    budget,
    balanceTopUpAmount,
    calculateExceededBudget,
  ]);

  return (
    <>
      {modals?.isModalStripe ? (
        <ModalStripeForm
          orderHandler={(payment_method_id) => {
            dispatch({
              type: MAKE_ORDER,
              payload: {
                id: group_order_id,
                group_order_items: compilingProductToOrder(
                  products?.myProducts,
                  settings.auth
                ),
                path_rerender: slug_uuid,
                user_id: settings.auth.id,
                modalOpen: true,
                payment_method_id,
                group_order_member_id,
              },
            });
          }}
          exceededAmount={products?.exceededAmount}
          onHide={() => {
            dispatch({
              type: CLIENT_SECRET_EXCEEDED_AMOUNT,
              payload: {
                clientSecret: null,
                exceededAmount: null,
                user_id: slug_uuid,
              },
            });
            dispatch({ type: CLOSE_MODAL_STRIPE, payload: slug_uuid });
          }}
          clientSecret={products?.clientSecret}
        />
      ) : null}
      {modals?.isModalEditProfile ? (
        <ModalEditProfile
          error={form.form?.error?.message}
          user_id={slug_uuid}
          groupOrders={groupOrders}
          show={modals.isModalEditProfile}
          auth={common.settings?.[slug_uuid]?.auth}
          onHide={() =>
            dispatch({
              type: MODAL_TOGGLE,
              payload: { data: "isModalEditProfile", user_id: slug_uuid },
            })
          }
        />
      ) : null}
      {modals?.isModalBudgetAllocate ? (
        <ModalBudgetAllocate
          show={modals.isModalBudgetAllocate}
          myProducts={products?.myProducts}
          tax_info={products?.tax_info}
          setClientSecret={setClientSecret}
          clientSecret={clientSecret}
          currency={groupOrders?.tax_info?.currency}
          budget={groupOrders?.per_person_budget?.cents}
          onConfirm={() => {
            dispatch({ type: OPEN_MODAL_STRIPE, payload: slug_uuid });
            dispatch({
              type: CLOSE_MODAL_BUDGET_ALLOCATED,
              payload: slug_uuid,
            });
          }}
          onHide={() => {
            dispatch({
              type: CLOSE_MODAL_BUDGET_ALLOCATED,
              payload: slug_uuid,
            });
          }}
        />
      ) : null}
      {/* {modals?.isModalOrderEndCompleted ? (
        <ModalOrderEnd
          order_deadline={groupOrders?.order_deadline * 1000}
          groupOrders={groupOrders}
          show={modals.isModalOrderEndCompleted}
          date={groupOrders?.delivery_date_time * 1000}
          title="COMPLETED"
          onHide={() =>
            dispatch({
              type: MODAL_CHANGE_ORDER,
              payload: {
                user_id: slug_uuid,
                slug: "isModalOrderEndCompleted",
                info: null,
              },
            })
          }
        />
      ) : null} */}
      {modals?.isModalOrderEndCancelled ? (
        <ModalOrderEnd
          order_deadline={groupOrders?.order_deadline * 1000}
          groupOrders={groupOrders}
          show={modals.isModalOrderEndCancelled}
          date={groupOrders?.delivery_date_time * 1000}
          title="CANCELLED"
          onHide={() =>
            dispatch({
              type: MODAL_CHANGE_ORDER,
              payload: {
                user_id: slug_uuid,
                slug: "isModalOrderEndCancelled",
                info: null,
              },
            })
          }
        />
      ) : null}
      {modals?.isModalChangeOrder ? (
        <ModalChangeOrder
          group_order_member_id={group_order_member_id}
          changeOrderInfo={modals.changeOrderInfo}
          show={modals.isModalChangeOrder}
          user_id={slug_uuid}
          onHide={() =>
            dispatch({
              type: MODAL_CHANGE_ORDER,
              payload: {
                user_id: slug_uuid,
                slug: "isModalChangeOrder",
                info: null,
              },
            })
          }
        />
      ) : null}
      {modals?.isModalShareLink ? (
        <ModalShareLink
          show={modals.isModalShareLink}
          onHide={() =>
            dispatch({
              type: MODAL_TOGGLE,
              payload: { data: "isModalShareLink", user_id: slug_uuid },
            })
          }
        />
      ) : null}
      {modals?.isModalReplaced ? (
        <ModalReplaced
          user_id={slug_uuid}
          show={modals.isModalReplaced}
          onHide={() =>
            dispatch({
              type: MODAL_TOGGLE,
              payload: { data: "isModalReplaced", user_id: slug_uuid },
            })
          }
        />
      ) : null}
      {modals?.isModalOrderSuccess ? (
        <ModalOrderSuccess
          groupOrders={groupOrders}
          show={modals.isModalOrderSuccess}
          onHide={() =>
            dispatch({
              type: MODAL_TOGGLE,
              payload: { data: "isModalOrderSuccess", user_id: slug_uuid },
            })
          }
        />
      ) : null}
      {/* {modals?.isModalAuth ? <ModalAuth date={groupOrders?.delivery_date_time * 1000} error={form.form?.error?.message}
                groupOrders={groupOrders} user_id={slug_uuid} show={modals.isModalAuth}
                onHide={() => dispatch({ type: CLOSE_MODAL_AUTH, payload: slug_uuid })} /> : null} */}
      {modals?.modalProduct ? (
        <ModalProduct
          user_id={slug_uuid}
          show={modals.isModalProduct}
          orderTeam={modals.orderTeam}
          modalProductFlag={modals.modalProductFlag}
          budget={common.settings?.[slug_uuid]?.budget}
          products={products}
          balanceTopUpAmount={balanceTopUpAmount}
          extractedExceededPrice={extractedExceededPrice}
          currency={groupOrders?.tax_info?.currency}
          groupOrdersId={groupOrders.id}
          shared_url={groupOrders?.preset?.shared_url}
          myProductsId={products?.myProductsId}
          logo_image={groupOrders?.preset?.chefs[0]?.profile_image_url}
          product={product}
          onHide={() =>
            dispatch({ type: MODAL_TOGGLE_PRODUCT, payload: slug_uuid })
          }
          hideEdit={hideEdit}
        />
      ) : null}
      {modals?.isModalConfirmation ? (
        <ModalConfirmation
          user_id={slug_uuid}
          isResendCode={common.settings?.[slug_uuid]?.isResendCode}
          error={form.form?.error?.message}
          show={modals.isModalConfirmation}
          order_deadline={groupOrders?.order_deadline * 1000}
          onHide={() =>
            dispatch({
              type: MODAL_TOGGLE,
              payload: { data: "isModalConfirmation", user_id: slug_uuid },
            })
          }
        />
      ) : null}
    </>
  );
};
export default Modals;
