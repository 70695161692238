import React from "react";
import cn from "classnames";
import ImagePreview from "../ImagePreview";
import {
  ADD_MODAL_PRODUCT,
  MODAL_TOGGLE_PRODUCT,
} from "../../../store/Modal/Modal.constant";
import { useDispatch } from "react-redux";
import { DELETE_PRODUCT_MY_ORDER } from "../../../store/Products/Products.constant";

const ProductCard = ({
  user_id,
  modalProductFlag = "ADD",
  orderTeam = false,
  myProductsId,
  oldMyOrderId,
  className,
  product,
  isJoin,
  disabledSelect,
}) => {
  const dispatch = useDispatch();
  let active = false;
  myProductsId.map((id) => id === product.id && (active = true));

  let portions = 0;
  if (modalProductFlag === "CHANGE" || orderTeam) {
    product.menu_items.length
      ? Object.values(product.menu_items_mapping).map(
          (item) => (portions += +item?.quantity)
        )
      : (portions = product.quantity);
  }
  return (
    <div className={cn("elem__order", "order1", className)}>
      {product && isJoin ? null : (
        <div className="order__placeholder">
          <div className="placeholder__top" />
          <div className="placeholder__bottom">
            <div className="placeholder__sm" />
            <div className="placeholder__lg" />
            <div className="placeholder__md" />
          </div>
        </div>
      )}
      <div className="order__image">
        <div
          className="change__button"
          onClick={() => {
            dispatch({
              type: ADD_MODAL_PRODUCT,
              payload: {
                product: {
                  ...product,
                  quantity:
                    modalProductFlag === "CHANGE" || orderTeam
                      ? product.quantity
                      : 0,
                },
                flag: modalProductFlag,
                user_id: user_id,
                orderTeam: orderTeam,
              },
            });
            dispatch({ type: MODAL_TOGGLE_PRODUCT, payload: user_id });
          }}
        >
          <button type="button">View</button>
        </div>
        <ImagePreview image={product.main_image_url} alt="orderimage1" />
        {!disabledSelect &&
          (active ? (
            <div
              className="order__float active__order"
              onClick={() => {
                dispatch({
                  type: DELETE_PRODUCT_MY_ORDER,
                  payload: { data: product.id, user_id: user_id },
                });
              }}
            >
              <img src="/img/pathimage.svg" alt="path" />
            </div>
          ) : (
            <div
              className="order__float"
              onClick={() => {
                dispatch({
                  type: ADD_MODAL_PRODUCT,
                  payload: {
                    product: {
                      ...product,
                      quantity:
                        modalProductFlag === "CHANGE" || orderTeam
                          ? product.quantity
                          : 0,
                    },
                    flag: modalProductFlag,
                    user_id: user_id,
                    orderTeam: orderTeam,
                  },
                });
                dispatch({ type: MODAL_TOGGLE_PRODUCT, payload: user_id });
              }}
            >
              <img src="/img/pathimage.svg" alt="path" />
            </div>
          ))}
        {orderTeam && myProductsId.indexOf(product.id) > -1 ? (
          <div className="prev__order">
            <p className="green">ALREADY IN CART</p>
          </div>
        ) : null}
        {!orderTeam && oldMyOrderId.indexOf(product.id) > -1 ? (
          <div className="prev__order">
            <p>PREVIOUSLY ORDERED</p>
          </div>
        ) : null}
      </div>
      <div className="order__info">
        <h6>{product.name}</h6>
        <div className="order__list">
          {/*<li>*/}
          {/*    <div className="order__rate">*/}
          {/*        <span>*/}
          {/*            <img src="/img/staricon.svg" alt="star" />*/}
          {/*        </span>*/}
          {/*        <p>4</p>*/}
          {/*    </div>*/}
          {/*</li>*/}
          {product.cuisine_types ? (
            <ul>
              <li className="spacer" />
              <li>{product.cuisine_types}</li>
            </ul>
          ) : null}
          {product.dietary_types ? (
            <ul>
              <li className="spacer" />
              <li>{product.dietary_types}</li>
            </ul>
          ) : null}
        </div>
        <div className="order__price">
          <p>${(+product.total_price_cents / 100).toFixed(2)}</p>
          {modalProductFlag === "CHANGE" || orderTeam ? (
            <p className="order-price__quantity">Qty: {portions}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default ProductCard;
