import "./style.scss";
import React, { useEffect, useState } from "react";
import Team from "./Team";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import Delivery from "./Delivery";
import cn from "classnames";
import Payment from "./Payment";
import Success from "./Success";
import Form from "../../modules/ui/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  COMMON_CLEAR_OLD_GO,
  FETCH_GROUP_ORDER,
} from "../../store/Common/Common.constant";
import {
  FORM_CLEAR_OLD_GO,
  FORM_CREATE_GO_SET_VAL,
} from "../../store/Form/Form.constant";
import ImagePreview from "../../modules/ui/ImagePreview";
import { Helmet } from "react-helmet";
import { WidgetHeader } from "../../widgets";
import { MODAL_CLEAR_OLD_GO } from "../../store/Modal/Modal.constant";
import { PRODUCTS_CLEAR_OLD_GO } from "../../store/Products/Products.constant";
import ModalCancelGroupOrder from "../../modules/ui/Modals/ModalCancelGroupOrder";
import { format } from "date-fns";

const StepNumber = ({ number, name, progress, hashName, location }) => {
  return (
    <div
      className={cn("elem__step", {
        active__step: progress === "active",
        done__step: progress === "done",
      })}
    >
      <div className="step__number">
        {progress === "done" ? (
          location.hash !== "#success" ? (
            <NavLink to={location.pathname + "#" + hashName}>
              <img src="/img/activestep.svg" alt="activestep" />
            </NavLink>
          ) : (
            <span>
              <img src="/img/activestep.svg" alt="activestep" />
            </span>
          )
        ) : (
          <span>{number}</span>
        )}
      </div>
      <div className="step__info">
        <p>{name}</p>
      </div>
    </div>
  );
};

const TeamOrder = ({ location, match }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("CateredClub");
  const [edit, setEdit] = useState(false);
  const [address, setAddress] = useState({});
  const [modalCancel, setModalCancel] = useState(false);
  const common = useSelector((state) => state.common);
  const groupOrders = common.groupOrders?.[match.params?.uuid];
  const form = useSelector((state) => state.form);
  const createGroupOrder = form.form?.createGroupOrder?.[match.params?.uuid];

  const onFocusPage = () => {
    if (localStorage.getItem("uuid") !== match.params.uuid) {
      localStorage.setItem("uuid", match.params?.uuid);
      window.location.reload();
    }
  };
  window.addEventListener("focus", onFocusPage);

  const onLoad = () => {
    localStorage.setItem("uuid", match.params?.uuid);
    const clearDate = localStorage.getItem("clearDate");
    if (
      common?.groupOrders &&
      (!clearDate || +clearDate < new Date().valueOf() - 604800000)
    ) {
      let arrOldUuid = Object.keys(common?.groupOrders)
        .map(
          (item) =>
            common?.groupOrders[item]?.order_deadline > 0 &&
            new Date(common?.groupOrders[item]?.order_deadline * 1000) <
              new Date() &&
            match.params?.uuid !== item &&
            item
        )
        .filter((i) => i);
      dispatch({ type: COMMON_CLEAR_OLD_GO, payload: arrOldUuid });
      dispatch({ type: MODAL_CLEAR_OLD_GO, payload: arrOldUuid });
      dispatch({ type: PRODUCTS_CLEAR_OLD_GO, payload: arrOldUuid });
      dispatch({ type: FORM_CLEAR_OLD_GO, payload: arrOldUuid });
      localStorage.setItem("clearDate", new Date().valueOf());
    }
    dispatch({
      type: FETCH_GROUP_ORDER,
      payload: { uuid: match.params?.uuid },
    });
    if (location.search) {
      let address = {};
      location.search
        .slice(1)
        .split("&")
        .map((i) => (address[i.split("=")[0]] = i.split("=")[1]));
      const addressObj = {
        delivery_address: decodeURI(address?.address),
        date: new Date(
          `${format(
            new Date(address?.delivery_date),
            "MMM d yyyy"
          )} ${decodeURI(address?.delivery_time)}`
        ),
        time: decodeURI(address?.delivery_time),
        delivery_latitude: decodeURI(address?.lat),
        delivery_longitude: decodeURI(address?.lng),
      };
      setAddress(addressObj);
      dispatch({
        type: FORM_CREATE_GO_SET_VAL,
        payload: { values: addressObj, uuid: match.params?.uuid },
      });
    }
  };
  useEffect(onLoad, []);

  const onLoadGroupOrder = () => {
    if (
      (common.createGroupOrder?.[match.params?.uuid]?.payment_complete &&
        groupOrders?.delivery_date_time * 1000 > new Date().valueOf()) ||
      groupOrders?.payment_status === "intent_completed" ||
      groupOrders?.payment_status === "setup_intent_created" ||
      groupOrders?.payment_status === "card_saved"
    ) {
      setEdit(true);
    }
  };
  useEffect(onLoadGroupOrder, [groupOrders]);

  if (groupOrders && title !== groupOrders.preset.name) {
    setTitle(groupOrders.preset.name);
  }
  const gapDaysInHours = groupOrders?.default_gap_days * 24;
  // const minDate = edit ? groupOrders?.delivery_date_time * 1000 - 900000 : new Date().valueOf() + 7200000 + (groupOrders?.preset?.pre_order_notice_hour * 3600000);
  const minDate =
    new Date().valueOf() +
    7200000 +
    (groupOrders?.preset?.pre_order_notice_hour +
      (gapDaysInHours && gapDaysInHours > 0 ? gapDaysInHours : 0)) *
      3600000;
  let defaultValues = createGroupOrder
    ? { ...createGroupOrder, date: new Date(createGroupOrder.date) }
    : {
        storey: ["1"],
        save_card: false,
        isStorey: false,
        time: "11:30 AM",
        date: minDate ? new Date(minDate) : null,
      };

  const loading = (resolve, reject) => (!groupOrders ? resolve : reject);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta id="meta-description" name="description" content={title} />
      </Helmet>

      <WidgetHeader />

      {modalCancel ? (
        <ModalCancelGroupOrder
          id={groupOrders?.id}
          show={modalCancel}
          onHide={() => setModalCancel(false)}
        />
      ) : null}

      <div className="form__block">
        <div className="container">
          <div className="inner__form">
            <div className="form__head">
              {loading(
                <div className="placeholder__image form__head" />,
                <img
                  src={groupOrders?.preset?.banner_image_url}
                  alt="formimage"
                />
              )}
            </div>
            <div className="form__head--info">
              <div className="head__logo">
                <a
                  href={
                    groupOrders?.preset?.shared_url
                      ? groupOrders?.preset?.shared_url
                      : "/"
                  }
                >
                  {loading(
                    <div className="placeholder__image form__head" />,
                    <ImagePreview
                      image={groupOrders?.preset?.chefs[0]?.profile_image_url}
                      alt="formimage"
                    />
                  )}
                </a>
              </div>
              <div className="head__info">
                <h6>{loading("loading...", groupOrders?.preset?.name)}</h6>
                <p>
                  {loading("loading...", groupOrders?.preset?.short_address)}
                </p>
              </div>
            </div>
            <div className="step__form">
              <StepNumber
                number={1}
                location={location}
                hashName="team"
                name="Order Details"
                progress={location.hash === "#team" ? "active" : "done"}
              />
              <StepNumber
                number={2}
                location={location}
                hashName="delivery"
                name="Order Details"
                progress={
                  location.hash === "#delivery"
                    ? "active"
                    : location.hash === "#team"
                    ? null
                    : "done"
                }
              />
              <StepNumber
                number={3}
                location={location}
                hashName="payment"
                name="Payment Details"
                progress={
                  location.hash === "#payment"
                    ? "active"
                    : location.hash === "#success"
                    ? "done"
                    : null
                }
              />
              <StepNumber
                number={4}
                location={location}
                hashName="success"
                name="Invite"
                progress={location.hash === "#success" ? "active" : null}
              />
            </div>
            <div className="form__wrapper">
              {form.form?.error?.message ? (
                <span className="form__error">{form.form?.error?.message}</span>
              ) : null}
              {location.hash === "#success" ? (
                <Success
                  slug={groupOrders?.slug}
                  creator_id={groupOrders?.creator_id}
                  loadCSV={
                    common.createGroupOrder?.[match.params?.uuid]?.loadCSV
                  }
                  error={form.form.error?.message}
                  id={groupOrders?.id}
                  uuid={match.params?.uuid}
                  payment_complete={
                    common.createGroupOrder?.[match.params?.uuid]
                      ?.payment_complete
                  }
                  pathname={location.pathname}
                />
              ) : null}
              <Form defaultValues={defaultValues}>
                {!location.hash ? (
                  <Redirect to={location.pathname + "#team"} />
                ) : null}
                {location.hash === "#team" ? (
                  <Team
                    setModalCancel={setModalCancel}
                    address={address}
                    setAddress={setAddress}
                    minDate={minDate}
                    edit={edit}
                    uuid={match.params?.uuid}
                    error={common?.error}
                    groupOrders={groupOrders}
                    pathname={location.pathname}
                    loading={common.loading}
                  />
                ) : null}
                {location.hash === "#delivery" ? (
                  <Delivery
                    uuid={match.params?.uuid}
                    loading={common.loading}
                    pathname={location.pathname}
                    payment_intent={
                      common.createGroupOrder?.[match.params?.uuid]
                        ?.payment_intent
                    }
                    groupOrders={groupOrders}
                    edit={edit}
                  />
                ) : null}
                {location.hash === "#payment" ? (
                  <Payment
                    error={form.form.error}
                    uuid={match.params?.uuid}
                    loading={common.loading}
                    pathname={location.pathname}
                    currency={groupOrders?.tax_info?.currency}
                    payment_intent={
                      common.createGroupOrder?.[match.params?.uuid]
                        ?.payment_intent
                    }
                    id={groupOrders?.id}
                    edit={edit}
                    payment_complete={
                      common.createGroupOrder?.[match.params?.uuid]
                        ?.payment_complete
                    }
                  />
                ) : null}
                <div className="after__form">
                  <p>
                    Orders are processed during business hours Mon-Fri, 6AM-5PM
                    (PST) and your order may be subject to restaurant and
                    delivery capacity. We will only contact you should any
                    issues arise with your order request. Eat Well!
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(TeamOrder);
