import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Star,
  CalendarArrowDown,
  CalendarArrowUp,
  HandPlatter,
} from "lucide-react";
import { format } from "date-fns";
import ImagePreview from "../../modules/ui/ImagePreview";
import { JOINED_GROUP_ORDER } from "../../store/GroupOrder/GroupOrder.constant";
const Card = ({
  order_deadline,
  joined,
  delivery_date_time,
  id,
  search_result_image_url,
  name,
  slug,
  restaurant_name,
  status,
}) => {
  const hideEdit = status === "waiting_for_delivery" || status === "delivered";
  const dispatch = useDispatch();
  const history = useHistory();
  const onClickJoined = useCallback(() => {
    dispatch({ type: JOINED_GROUP_ORDER, payload: { id, slug, history } });
  }, [id]);
  return (
    <div className="card-outer">
      <div className="card-inner">
        {hideEdit ? (
          // Show only "View Order" if status is "delivered"
          <Link
            to={`/group_order/${slug || id}`}
            target="_self"
            className="btn-edit"
          >
            View Order
          </Link>
        ) : (
          // Show "Joined" or "Yet to Join" / "Join" only when status is NOT "delivered"
          <>
            <button className={`btn-joined ${joined ? "" : "active"}`}>
              {joined ? (
                <>
                  <Star />
                  Joined
                </>
              ) : (
                <div onClick={onClickJoined} style={{ fontSize: "15px" }}>
                  Yet to Join
                </div>
              )}
            </button>
            {joined ? (
              <Link
                to={`/group_order/${slug}`}
                target="_self"
                className="btn-edit"
              >
                View Order
              </Link>
            ) : (
              <div
                onClick={onClickJoined}
                className="btn-edit d-flex align-items-center justify-content-center"
                style={{ fontSize: "14px", gap: "7px" }}
              >
                <HandPlatter style={{ opacity: "0.6" }} />
                Join
              </div>
            )}
          </>
        )}
        <div>
          <ImagePreview
            image={search_result_image_url}
            defaultImage={"/img/orderimage3.png"}
            alt="modalimage"
            style={{ borderRadius: "0 0 10px 10px" }}
          />
        </div>
      </div>
      <div className="bottom-inner">
        <h2>{name}</h2>
        <p>{restaurant_name}</p>
      </div>
      <div className="bottom-outer">
        <div className="d-flex align-items-end order-date">
          <div>
            <div className="d-flex align-items-center justify-content-center calendar-heading">
              <CalendarArrowDown />
              <p className="text-center">Submit Order Before:</p>
            </div>
            <h2 className="text-center br-white">
              {format(
                new Date(order_deadline * 1000),
                "EEEE, MMMM do,yyyy, h:mm aaa"
              )}
            </h2>
          </div>
          <div>
            <div className="d-flex align-items-center justify-content-center calendar-heading">
              <CalendarArrowUp />
              <p className="text-center">Order Delivery On:</p>
            </div>
            <h2 className="text-center br-white">
              {format(
                new Date(delivery_date_time * 1000),
                "EEEE, MMMM do,yyyy, h:mm aaa"
              )}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
