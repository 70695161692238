import FormInputNumber from "../Form/FormInput/FormInputNumber";
import React from "react";

const OrderSelection = ({
  title,
  currency,
  name,
  price,
  quantity,
  budget,
  disabled,
  ...prop
}) => {
  const { hideEdit } = prop;
  // let max = price > budget ? quantity : 99999;
  let max = 99999;
  // console.log({ budget: (+budget / 100).toFixed(2) });
  return (
    <div className="elem__selection">
      <div className="selected__info">
        <p>{title} </p>

        <span>+ {currency + (+price / 100).toFixed(2)}</span>
      </div>
      <FormInputNumber
        max={max}
        hideEdit={hideEdit}
        disabled={disabled}
        {...prop}
        withoutContainer={true}
        name={name}
      />
    </div>
  );
};
export default OrderSelection;
