import {
  TabLayoutMain,
  TabLayoutSubMain,
} from "../../../modules/ui/Tab/TabLayout";
import TabHeader from "../../../modules/ui/Tab/TabHeader";
import TabLink from "../../../modules/ui/Tab/TabLink";
import TabContent from "../../../modules/ui/Tab/TabContent";
import Tab from "../../../modules/ui/Tab";
import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import ProductCard from "../../../modules/ui/ProductCard";
import OrderTeam from "../../../modules/ui/OrderTeam";
import Form from "../../../modules/ui/Form";
import FormInput from "../../../modules/ui/Form/FormInput";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { FETCH_GROUP_ORDER } from "../../../store/Common/Common.constant";
import { toast } from "react-toastify";
import cn from "classnames";

const OrderTeamWrap = ({
  setAll,
  all,
  activeTab,
  refId,
  auth_id,
  user_id,
  currency,
  settings,
  products,
  presets,
  groupOrderItems,
  groupOrdersMembers,
  isJoin,
  hideBtn,
}) => {
  const { values } = useFormikContext();
  const [scroll, setScroll] = useState();
  const [col, setCol] = useState(3);

  let group_order_items = [...groupOrderItems];
  if (values.filter) {
    let filtered = [];
    group_order_items.map((item) => {
      if (
        item.first_name?.toLowerCase().indexOf(values.filter.toLowerCase()) >
        -1 ||
        item.last_name?.toLowerCase().indexOf(values.filter.toLowerCase()) >
        -1 ||
        `${item.last_name?.toLowerCase()} ${item.first_name?.toLowerCase()}`.indexOf(
          values.filter.toLowerCase()
        ) > -1 ||
        `${item.first_name?.toLowerCase()} ${item.last_name?.toLowerCase()}`.indexOf(
          values.filter.toLowerCase()
        ) > -1
      ) {
        filtered.push(item);
      }
      return null;
    });
    group_order_items = filtered;
  }

  let group_items = group_order_items?.map(
    (item) => item.group_order_member_id
  );
  let onlyUnique = (value, index, self) => self.indexOf(value) === index;
  group_items = group_items.filter(onlyUnique);

  let effectScroll = () => {
    if (col <= group_items.length && activeTab === "team__container") {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
    if (activeTab !== "team__container") {
      setCol(3);
      setAll(false);
    }
  };
  useEffect(effectScroll, [activeTab, col]);
  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  let allTeamOrder = () => {
    all && setCol(group_items.length);
  };
  useEffect(allTeamOrder, [all]);

  const scrollEffect = () => {
    if (
      activeTab === "team__container" &&
      scroll >=
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight -
      408 &&
      col <= group_items.length
    ) {
      setCol(col + 3);
    }
  };
  useEffect(scrollEffect, [scroll]);

  return (
    <div className="team__order--list">
      {group_items.length ? (
        group_items.map((id, index) => {
          if (index < col) {
            return (
              <OrderTeam
                refId={refId}
                hideBtn={hideBtn}
                id={id}
                auth_id={auth_id}
                user_id={user_id}
                currency={currency}
                isJoin={isJoin}
                products={products}
                key={index}
                menus={presets.chefs[0].menus}
                image={
                  groupOrdersMembers
                    .map((item) => item.id === id && item.profile_image_url)
                    .filter((i) => i)[0]
                }
                budget={settings?.budget}
                items={group_order_items
                  .map((item) =>
                    item.group_order_member_id === id ? item : null
                  )
                  .filter((item) => item)}
              />
            );
          } else return null;
        })
      ) : values?.filter ? (
        <div className="user_not_found">
          User with this first or last name was not found
        </div>
      ) : null}
    </div>
  );
};

const TopTabs = ({
  auth_id,
  id,
  joined_member,
  user_id,
  currency,
  presets,
  products,
  settings,
  groupOrdersItems,
  groupOrdersMembers,
  isJoin,
  hideEdit,
  isManager,
  isJoined,
}) => {
  const [activeTab, setActiveTab] = useState("order__inner");
  const [refId, setRefId] = useState(0);
  const [changeTeamOrder, setChangeTeamOrder] = useState(false);
  const [all, setAll] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setChangeTeamOrder(true);
    setTimeout(() => setChangeTeamOrder(false), 1000);
  }, [joined_member]);
  const onClickTeamOrder = (id) => {
    setActiveTab("team__container");
    setAll(true);
    setRefId(id);
  };
  const onLoad = () => {
    if (!isJoin) return;
    console.log(`>> connecting to ${process.env.REACT_APP_API_WS_URL}`);
    let socket = new WebSocket(process.env.REACT_APP_API_WS_URL);
    socket.onopen = function (event) {
      const msg = {
        command: "subscribe",
        identifier: JSON.stringify({
          id: id,
          channel: "GroupOrderChannel",
        }),
      };
      socket.send(JSON.stringify(msg));
    };
    socket.onclose = function (event) {
      console.log("WebSocket is closed.");
    };
    socket.onmessage = function (event) {
      const response = event.data;
      const msg = JSON.parse(response);
      if (msg.type === "ping") return;
      if (msg.message) {
        dispatch({ type: FETCH_GROUP_ORDER, payload: { uuid: user_id } });
        toast(() => {
          let expensive = { sub_total: 0 };
          msg.message.group_order_items.map((item) => {
            if (item.sub_total > expensive.sub_total) {
              expensive = { ...item };
            }
          });
          return (
            <div
              onClick={() =>
                onClickTeamOrder(msg.message.group_order_member.id)
              }
              className="new__teamOrder"
            >
              <div className="profile__image">
                {msg.message.group_order_member?.profile_image_url ? (
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      msg.message.group_order_member?.profile_image_url
                    }
                    alt="profile"
                  />
                ) : (
                  <span>
                    {msg.message.group_order_member?.first_name[0]?.toUpperCase() +
                      msg.message.group_order_member?.last_name[0]?.toUpperCase()}
                  </span>
                )}
              </div>
              <div className="profile__info">
                <span>{`${msg.message.group_order_member.first_name} ${msg.message.group_order_member.last_name} ordered:`}</span>
                <p>
                  {expensive.menu_name} - ${expensive.sub_total / 100}
                </p>
              </div>
            </div>
          );
        });
      }
    };
    socket.onerror = function (error) {
      console.log("WebSocket Error: ", error);
    };
  };
  useEffect(onLoad, [isJoin]);

  let tabContent = [];
  let outsideBudget = [];
  if (presets) {
    presets.chefs[0].menu_category_names.map((category, index) => {
      tabContent[index] = [];
      presets.chefs[0].menus.map((menu) => {
        if (menu.category_name === category) {
          tabContent[index].push(menu);
        }
        return null;
      });
      return null;
    });
    presets.chefs[0].menus.map(
      (dish) =>
        dish.total_price_cents > settings?.budget && outsideBudget.push(dish)
    );
  }
  const outsideFromBudget = useMemo(
    () => (settings?.budget > 0 ? (settings?.budget / 100).toFixed(2) : 0),
    [settings?.budget]
  );

  if (hideEdit) {
    if (isManager) {
      return (
        <div className="team__container">
          <Form className="container">
            <div className="head__order">
              <h2>
                Here’s what your team ordered{" "}
                {joined_member ? `(${joined_member})` : null}
              </h2>
              {(
                <div className="filter__order">
                  <p>Filter:</p>
                  <FormInput
                    withoutContainer={true}
                    name="filter"
                    type="text"
                    placeholder="Search by name"
                  />
                </div>
              )}
            </div>
            {groupOrdersItems ? (
              <OrderTeamWrap
                setAll={setAll}
                all={all}
                activeTab={activeTab}
                refId={refId}
                auth_id={auth_id}
                user_id={user_id}
                currency={currency}
                isJoin={isJoin}
                products={products}
                groupOrdersMembers={groupOrdersMembers}
                groupOrderItems={groupOrdersItems}
                settings={settings}
                presets={presets}
                hideBtn={hideEdit}
              />
            ) : (
              <h3 style={{
                marginTop: "60px",
                marginBottom: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              >It looks like you didn't order anything for this day!</h3>
            )}
          </Form>
        </div>
      );
    }
    return null;
  }
  return (
    <Tab
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      current="order__inner"
      layout={TabLayoutMain}
    >
      <TabHeader>
        <TabLink id="order__inner">
          <span>Select items for your order</span>
        </TabLink>
        <TabLink id="team__container">
          <span>
            See what your team ordered{" "}
            {joined_member ? (
              <span className={cn({ active: changeTeamOrder })}>
                {joined_member}
              </span>
            ) : null}
          </span>
        </TabLink>
      </TabHeader>
      <div className="order__switcher--box">
        <TabContent id="order__inner">
          <div className="order__inner">
            <div className="container">
              <div className="order__main">
                <Tab current="order0" layout={TabLayoutSubMain}>
                  <TabHeader>
                    {presets?.chefs[0].menu_category_names.map(
                      (item, index) => (
                        <TabLink key={index} id={"order" + index}>
                          <span>
                            {item}
                            <span>
                              {
                                tabContent[index].filter(
                                  (item) =>
                                    item.total_price_cents < settings?.budget
                                ).length
                              }
                            </span>
                          </span>
                        </TabLink>
                      )
                    )}
                  </TabHeader>
                  {tabContent.map((item, index) => {
                    let max = true;
                    let min = true;
                    item.map((dish) => {
                      dish.total_price_cents <= settings?.budget &&
                        (max = false);
                      dish.total_price_cents > settings?.budget &&
                        (min = false);
                      return null;
                    });
                    return (
                      <TabContent key={index} id={"order" + index}>
                        <div>
                          {outsideFromBudget > 0 ? (
                            <div className="order__box">
                              <div className="order__within">
                                <p>
                                  Within budget ({currency + outsideFromBudget}{" "}
                                  and under)
                                </p>
                                {!max ? (
                                  <div className="order__within--container">
                                    {item.map((dish, index) =>
                                      dish.total_price_cents <=
                                        settings?.budget ? (
                                        <ProductCard
                                          user_id={user_id}
                                          isJoin={isJoin}
                                          myProductsId={products.myProductsId}
                                          oldMyOrderId={products.oldMyOrderId}
                                          key={index}
                                          product={dish}
                                        />
                                      ) : null
                                    )}
                                  </div>
                                ) : (
                                  <div className="check__other">
                                    <span>
                                      <img
                                        src="/img/foodicon.svg"
                                        alt="foodicon"
                                      />
                                    </span>
                                    <p>
                                      You have a great appetite, you don’t have
                                      more to spend in this category! Check
                                      other categories.
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="order__outside">
                          {!min ? (
                            <>
                              <p className="d-flex align-items-center">
                                <span>
                                  {outsideFromBudget > 0
                                    ? `Outside of budget (Over ${currency + outsideFromBudget
                                    })`
                                    : "Add top up items to your order"}
                                </span>
                                <span
                                  style={{ fontSize: "16px", color: "#e69220" }}
                                  className="ml-3 fw-bold "
                                >
                                  Add these items to your order using your
                                  credit card.
                                </span>
                              </p>
                              <div className="order__outside--container">
                                {item.map((dish, index) =>
                                  dish.total_price_cents > settings?.budget ? (
                                    <ProductCard
                                      user_id={user_id}
                                      isJoin={isJoin}
                                      myProductsId={products.myProductsId}
                                      oldMyOrderId={products.oldMyOrderId}
                                      key={index}
                                      product={dish}
                                    />
                                  ) : null
                                )}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </TabContent>
                    );
                  })}
                </Tab>
              </div>
            </div>
          </div>
        </TabContent>
        <TabContent id="team__container">
          <div className="team__container">
            <Form className="container">
              <div className="head__order">
                <h2>
                  Here’s what your team ordered{" "}
                  {joined_member ? `(${joined_member})` : null}
                </h2>
                <div className="filter__order">
                  <p>Filter:</p>
                  <FormInput
                    withoutContainer={true}
                    name="filter"
                    type="text"
                    placeholder="Search by name"
                  />
                </div>
              </div>
              {groupOrdersItems ? (
                <OrderTeamWrap
                  setAll={setAll}
                  all={all}
                  activeTab={activeTab}
                  refId={refId}
                  auth_id={auth_id}
                  user_id={user_id}
                  currency={currency}
                  isJoin={isJoin}
                  products={products}
                  groupOrdersMembers={groupOrdersMembers}
                  groupOrderItems={groupOrdersItems}
                  settings={settings}
                  presets={presets}
                />
              ) : null}
            </Form>
          </div>
        </TabContent>
      </div>
    </Tab>
  );
};
export default TopTabs;
