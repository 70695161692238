import Modal from "react-bootstrap/Modal";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import Form from "../../Form";
import FormTextarea from "../../Form/FormTextarea";
// import Rates from "../../Rates";
import OrderSelection from "../../OrderSelection";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import {
  ADD_PRODUCT_MY_ORDER,
  DELETE_PRODUCT_MY_ORDER,
} from "../../../../store/Products/Products.constant";
import ImagePreview from "../../ImagePreview";
import { CHANGE_MODAL_PRODUCT } from "../../../../store/Modal/Modal.constant";
import { FETCH_GROUP_ORDER_ITEMS } from "../../../../store/Common/Common.constant";
import { toast } from "react-toastify";

const ModalProductWrap = ({
  user_id,
  orderTeam,
  modalProductFlag,
  budget,
  currency,
  products,
  shared_url,
  logo_image,
  groupOrdersId,
  product,
  onHide,
  myProductsId,
  balanceTopUpAmount,
  extractedExceededPrice,
  hideEdit,
}) => {
  const [error, setError] = useState(false);
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  const notify = () => toast("Your order has been successfully updated.");
  const active = products?.myProductsId.indexOf(product.id);
  const defaultBudget =
    active > -1 && modalProductFlag === "CHANGE"
      ? budget + products?.myProducts[active].total_price_cents
      : budget;
  const [budgetModal, setBudgetModal] = useState(defaultBudget);
  const allotedBudget = useMemo(
    () =>
      product.total_price_cents < budget
        ? (budget - product.total_price_cents) / 100
        : 0,
    [product.total_price_cents, budget]
  );

  const onChangeValue = () => {
    dispatch({
      type: CHANGE_MODAL_PRODUCT,
      payload: { data: values, user_id: user_id },
    });
  };
  useLayoutEffect(onChangeValue, [values]);

  const onChangeProduct = () => {
    setBudgetModal(+defaultBudget - +product.total_price_cents);
  };
  useEffect(onChangeProduct, [product]);

  let portions = 0;
  values.menu_items_mapping &&
    Object.values(values.menu_items_mapping).map(
      (item) => (portions += +item.quantity)
    );

  const parsedDescription =
    product?.description &&
    product?.description.replace(/(\r\n|\n|\r)/gm, "<br />");

  return (
    <div className="product__double">
      <div className="product__info">
        <div className="product__head">
          <div className="left__product--head">
            <h2>{product?.name}</h2>
            {/*<Rates rates={4} />*/}
            <div className="product__price">
              <p>
                <span>{currency + (product.price_cents / 100).toFixed(2)}</span>{" "}
                / person
              </p>
            </div>
          </div>
          <div className="right__product--head">
            <a href={shared_url ? shared_url : "/"}>
              <ImagePreview image={logo_image} alt="closemodal" />
            </a>
          </div>
        </div>
        <div className="product__desc">
          <div className="product__image">
            <ImagePreview image={product?.main_image_url} alt="productimage" />
          </div>
          <div className="tags__product">
            {/*{product?.cuisine_types?.map((item, index) => <div key={index} className="el__tag">*/}
            {/*    <p>{item}</p>*/}
            {/*</div>)}*/}
          </div>
          <p>{ReactHtmlParser(parsedDescription)}</p>
        </div>
      </div>
      <div className="product__cart">
        <div className="order__selection">
          {error ? (
            <div className="errorProduct">
              <div className="errorProduct__img">
                <ImagePreview image="/img/modalwarn.svg" alt="error" />
              </div>
              <span>{error}</span>
            </div>
          ) : null}
          <p>Order Selection</p>
          <div className="selection__list">
            {product.menu_items.map((item) => (
              <OrderSelection
                hideEdit={hideEdit}
                disabled={orderTeam}
                currency={currency}
                quantity={values.menu_items_mapping?.[item.id]?.quantity}
                budget={budgetModal}
                min={0}
                key={item.id}
                title={item.name}
                price={item.price_cents}
                name={`menu_items_mapping[${item.id}][quantity]`}
              />
            ))}
            {!product.menu_items.length ? (
              <OrderSelection
                hideEdit={hideEdit}
                disabled={orderTeam}
                currency={currency}
                quantity={values.quantity}
                budget={budgetModal}
                min={0}
                title={product.name}
                price={product.price_cents}
                name="quantity"
              />
            ) : null}
            <div className="info__selection">
              <span>ALLOTED BUDGET REMAINING:</span>
              <p>{currency + +allotedBudget}</p>
            </div>
            <div className="info__selection">
              <span>EXTRA AMOUNT TO BE PAID BY YOU:</span>
              <p>{currency + +extractedExceededPrice}</p>
            </div>
            <div className="info__selection">
              <span>Total Portions:</span>
              <p>{product.menu_items.length ? portions : product.quantity}</p>
            </div>
            <div className="info__selection">
              <span>Total Price:</span>
              <p>{currency + (+product.total_price_cents / 100).toFixed(2)}</p>
            </div>
          </div>
          {!hideEdit ? (
            <div className="special__instructions">
              <p>Special Instructions</p>
              <FormTextarea
                withoutContainer={true}
                name="special_instruction"
                placeholder="Please type in any special instructions you have."
                options={{ cols: 30, rows: 10 }}
              />
            </div>
          ) : null}
          <div className="save__changes">
            {!(product.menu_items.length ? portions : product.quantity) &&
            active > -1 ? (
              <button
                type="button"
                onClick={() => {
                  notify();
                  dispatch({
                    type: DELETE_PRODUCT_MY_ORDER,
                    payload: { data: product.id, user_id: user_id },
                  });
                  dispatch({
                    type: FETCH_GROUP_ORDER_ITEMS,
                    payload: { data: groupOrdersId, user_id: user_id },
                  });
                  onHide();
                }}
              >
                Remove product
              </button>
            ) : (
              // : <button disabled={!product.total_price_cents || +budgetModal < 0} type='button' onClick={() => {
              !hideEdit && (
                <button
                  disabled={!product.total_price_cents}
                  type="button"
                  onClick={() => {
                    if (
                      (product.menu_items.length
                        ? portions
                        : product.quantity) >= product?.min_order_amount
                    ) {
                      notify();
                      setError(false);
                      // if (product.total_price_cents && +budgetModal >= 0) {
                      if (product.total_price_cents) {
                        dispatch({
                          type: ADD_PRODUCT_MY_ORDER,
                          payload: {
                            product: product,
                            flag: modalProductFlag,
                            user_id: user_id,
                          },
                        });
                        onHide();
                      }
                    } else {
                      setError(
                        `Minimum order amount ${product?.min_order_amount} not met. Please increase quantity and continue.`
                      );
                    }
                    dispatch({
                      type: FETCH_GROUP_ORDER_ITEMS,
                      payload: { data: groupOrdersId, user_id: user_id },
                    });
                  }}
                >
                  {myProductsId.indexOf(product.id) > -1
                    ? "Save Changes"
                    : "Add to Cart"}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalProduct = ({ product, orderTeam, onHide, show, ...props }) => {
  if (!product) return null;
  return (
    <>
      <Modal className="product-modal" show={show} centered>
        <Modal.Body>
          <div className="modal__wrapper">
            <div
              className="inner__modal"
              onClick={(e) =>
                e.target.classList.contains("inner__modal") && onHide()
              }
            >
              <div className="product__wrapper">
                <button>
                  <img
                    src="/img/closemodal.svg"
                    alt="closemodal"
                    onClick={onHide}
                  />
                </button>
                <Form
                  name="OrderProduct"
                  min={0}
                  className="product__modal"
                  defaultValues={{
                    menu_items_mapping: product.menu_items_mapping,
                    quantity: product.quantity,
                    special_instruction: product.special_instruction,
                  }}
                >
                  <ModalProductWrap
                    orderTeam={orderTeam}
                    {...props}
                    product={product}
                    onHide={onHide}
                  />
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ModalProduct;
