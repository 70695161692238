import { common } from "../../shared/utils/UtilSaga";
import ApiService from "../../shared/services/ApiService";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_GROUP_ORDER_DASHBOARD,
  GROUP_ORDER_DASHBOARD_USER_DATA,
  GROUP_ORDER_DATA,
  IS_LOADING_GROUP_ORDER,
  JOINED_GROUP_ORDER,
  UPDATE_GROUP_ORDER_ITEM,
} from "./GroupOrder.constant";

function* workerGroupOrderDashboard(action) {
  const { ...props } = action.payload;
  yield put({ type: IS_LOADING_GROUP_ORDER, payload: true });
  const response = yield call(
    ApiService.groupOrderDashboard.fetchGroupOrderDashboard,
    props
  );
  if (response) {
    yield put({ type: GROUP_ORDER_DATA, payload: response });
    // yield put({ type: GROUP_ORDER_DASHBOARD_USER_DATA, payload:response?.user });
    yield put({ type: IS_LOADING_GROUP_ORDER, payload: false });
    return;
  }
}
function* workerUpdateJoined(action) {
  const { history, ...payload } = action.payload
  yield put({ type: IS_LOADING_GROUP_ORDER, payload: true });
  const data = yield call(
    ApiService.groupOrderDashboard.joinedGroupOrderApi,
    payload
  );
  yield put({ type: IS_LOADING_GROUP_ORDER, payload: false });
  if (data) {
    history.push(`/group_order/${payload.slug}`)
  }
}
function* watchUpdateJoined() {
  yield takeLatest(JOINED_GROUP_ORDER, common(workerUpdateJoined));
}
function* watchGroupOrderDashboard() {
  yield takeLatest(
    FETCH_GROUP_ORDER_DASHBOARD,
    common(workerGroupOrderDashboard)
  );
}

export default function groupOrderDashboardWatchers() {
  return [fork(watchGroupOrderDashboard), fork(watchUpdateJoined)];
}
