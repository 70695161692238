import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import {
  CREATE_PAYMENT_INTENT_GROUP_ORDER,
  UPDATE_PAYMENT_INTENT_GROUP_ORDER,
} from "../../../store/Common/Common.constant";
import LocationSearchInput from "../../../modules/ui/LocationSearchInput";
import { FORM_CREATE_GO_SET_VAL } from "../../../store/Form/Form.constant";
import Accordion from "../../../modules/ui/Accordion";
import FormInputAdd from "../../../modules/ui/Form/FormInput/FormInputAdd";
import FormInputCustom from "../../../modules/ui/Form/FormInput/FormInputCustom";
import FormTextarea from "../../../modules/ui/Form/FormTextarea";
import getDistanceFromLatLonInKm from "../../../hooks/getDistanceFromLatLonInKm";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const Delivery = ({ edit, uuid, loading, pathname, groupOrders }) => {
  const { values, setFieldValue } = useFormikContext();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const isUpdateable =
    edit &&
    (groupOrders?.company_name !== values?.company_name ||
      groupOrders?.delivery_address !== values?.delivery_address ||
      groupOrders?.delivery_suite !== values?.delivery_suite ||
      groupOrders?.delivery_instruction !==
        (values?.delivery_instruction || ""));
  const dispatch = useDispatch();
  let validate = () => {
    if (
      Object.values(values).length &&
      !values.per_person_budget &&
      !values.head_count &&
      !values.name &&
      !(values.confirm?.[0] === "yas")
    ) {
      return window.location.replace(pathname + "#team");
    }
  };
  useEffect(validate, [values]);
  const onLoadInfo = () => {
    setFieldValue(
      "delivery_address",
      groupOrders?.delivery_address || values?.delivery_address || ""
    );
    setFieldValue(
      "company_name",
      groupOrders?.company_name || values?.company_name || ""
    );
    groupOrders?.delivery_suite &&
      !values?.delivery_suite &&
      setFieldValue("delivery_suite", groupOrders?.delivery_suite);
    groupOrders?.delivery_instruction &&
      !values?.delivery_instruction &&
      setFieldValue("delivery_instruction", groupOrders?.delivery_instruction);
    if (groupOrders?.additional_delivery_options?.length) {
      setFieldValue("storey", groupOrders?.additional_delivery_options);
      setFieldValue("isStorey", true);
    }
  };
  useEffect(onLoadInfo, [groupOrders]);

  const onClickContinue = () => {
    if (values.delivery_address && values.company_name && !loading) {
      let {
        date,
        time,
        confirm,
        isStorey,
        storey,
        deadlineDate,
        deadlineTime,
        ...val
      } = values;
      dispatch({
        type: FORM_CREATE_GO_SET_VAL,
        payload: { values: values, uuid: uuid },
      });
      // if (!isUpdateable) {
      val.delivery_date_time = date.setHours(
        time.split(/[\s:]+/)[2] === "PM" && +time.split(/[\s:]+/)[0] !== 12
          ? +time.split(/[\s:]+/)[0] + 12
          : +time.split(/[\s:]+/)[0],
        time.split(/[\s:]+/)[1],
        0
      );
      val.order_deadline = deadlineDate.setHours(
        deadlineTime.split(/[\s:]+/)[2] === "PM" &&
          +deadlineTime.split(/[\s:]+/)[0] !== 12
          ? +deadlineTime.split(/[\s:]+/)[0] + 12
          : +deadlineTime.split(/[\s:]+/)[0],
        deadlineTime.split(/[\s:]+/)[1],
        0
      );

      // }
      dispatch({
        type: isUpdateable
          ? UPDATE_PAYMENT_INTENT_GROUP_ORDER
          : CREATE_PAYMENT_INTENT_GROUP_ORDER,
        payload: {
          group_order: {
            ...val,
            additional_delivery_options: isStorey ? storey : [],
          },
          id: groupOrders?.id,
          uuid: uuid,
          redirect: pathname + "#payment",
        },
      });
    } else {
      setSubmitSuccess(true);
    }
  };

  const chef = groupOrders?.preset?.chefs?.[0];
  let distanceDelivery =
    getDistanceFromLatLonInKm(
      +chef?.latitude,
      +chef?.longitude,
      +values?.delivery_latitude,
      +values?.delivery_longitude
    ) < chef?.max_delivery_distance;

  if (!values?.delivery_latitude && values?.delivery_address) {
    geocodeByAddress(values?.delivery_address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setFieldValue("delivery_latitude", latLng.lat);
        setFieldValue("delivery_longitude", latLng.lng);
      })
      .catch((error) => console.error("Error", error));
  }

  return (
    <>
      <div className="head__form">
        <h2>Delivery details</h2>
        <p>
          Complete your checkout details now so you <br /> don’t have to worry
          about it later.
        </p>
      </div>
      <div className="hidden__content float__field">
        <FormInputCustom
          error={submitSuccess && !values.company_name}
          required={true}
          name="company_name"
          title="Company Name*"
        />
        <div className="float__input">
          {values?.delivery_address || values?.delivery_address === "" ? (
            <LocationSearchInput
              error={submitSuccess && !values.delivery_address}
              value={values.delivery_address}
              name="delivery_address"
              setFieldValue={setFieldValue}
            />
          ) : null}
        </div>
        {values?.delivery_address && !distanceDelivery ? (
          <div className="form__error">
            You are too far from the establishment, maximum range is{" "}
            {chef?.max_delivery_distance}km (
            {(chef?.max_delivery_distance / 1.609344).toFixed(2)} miles) from{" "}
            {`${chef?.address1}, ${chef?.city}, ${chef?.country}`}
          </div>
        ) : null}
        <FormInputCustom name="delivery_suite" title="Suite #" />
        <FormTextarea
          classContainer="float__field"
          name="delivery_instruction"
          placeholder="Please type in any special instructions you have."
          options={{ cols: 30, rows: 10 }}
        />
        <Accordion
          disabled={edit}
          defaultToggle={values?.isStorey}
          modal={true}
          name="isStorey"
          title="Show Advanced Delivery Settings"
          modalTitle="Are you sure you want to close this? If this selection is closed then all selections will be removed? Proceed?"
        >
          <p>
            If your office requires delivery to more than one floor, you can
            input the floor numbers below and add as many floors as you need.
            When the group order is created and team members start joining, they
            will be able to select which floor they are on, so that the food can
            be delivered to several floors at once. All packaging will also be
            labelled to include the floor # that the food has to be delivered
            to.
          </p>
          <FormInputAdd name="storey" />
        </Accordion>
      </div>
      <div className="form__submit">
        <button
          disabled={!distanceDelivery}
          onClick={onClickContinue}
          type="button"
        >
          {isUpdateable ? "Update" : "Continue"}
        </button>
      </div>
      <div className="form__back">
        <NavLink to={pathname + "#team"}>Back</NavLink>
      </div>
    </>
  );
};
export default Delivery;
