import FormInput from "../index";
import React from "react";
import { useField, useFormikContext } from "formik";

const FormInputNumber = ({
  name,
  disabled,
  hideEdit,
  min = 1,
  max,
  className = "selected__counter",
  ...props
}) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const PLUS = "plus";
  const MINUS = "minus";
  const handleChange = (method) => {
    switch (method) {
      case MINUS:
        return +field.value - 1 >= min && setFieldValue(name, --field.value);
      case PLUS:
        return (
          (!max || +field.value + 1 <= max) &&
          setFieldValue(name, ++field.value)
        );
      default:
        return;
    }
  };
  return (
    <div className={className}>
      {!hideEdit ? (
        <button
          disabled={field.value === min || disabled}
          onClick={() => (disabled ? null : handleChange(MINUS))}
          type="button"
          className="minus__counter"
        >
          <img src="/img/minusicon.svg" alt="minusicon" />
        </button>
      ) : null}
      <FormInput
        type="number"
        name={name}
        {...props}
        options={{ min: min, max: max, disabled: disabled }}
      />
      {!hideEdit ? (
        <button
          disabled={field.value === max || disabled}
          onClick={() => (disabled ? null : handleChange(PLUS))}
          type="button"
          className="plus__counter"
        >
          <img src="/img/plusicon.svg" alt="minusicon" />
        </button>
      ) : null}
    </div>
  );
};

export default FormInputNumber;
