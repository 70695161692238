import React, { useMemo } from "react";
import "./style.scss";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { MODAL_TOGGLE } from "../../../store/Modal/Modal.constant";
import ImagePreview from "../../../modules/ui/ImagePreview";
import OrderInformation from "../../../modules/ui/OrderInformation";

const ShareIcon = ({ shareLink }) => {
  return (
    <div className="orders__right">
      <button type="button" onClick={shareLink}>
        <span>
          <img
            src="/img/shareicon.svg"
            alt="shareicon"
            style={{ width: "28px", height: "28px" }}
          />
        </span>
      </button>
    </div>
  );
};

const Invitation = ({
  user_id,
  groupOrders,
  settings,
  isJoin,
  balanceTopUpAmount,
  total_topup,
  hideEdit,
  isManager,
  consumed_budget,
  SubTotal,
  totalPaidByEmployees,
}) => {
  const dispatch = useDispatch();
  const presets = groupOrders?.preset;
  const shareLink = () => {
    dispatch({
      type: MODAL_TOGGLE,
      payload: { data: "isModalShareLink", user_id: user_id },
    });
  };
  const outsideFromBudget = useMemo(
    () => (settings?.budget > 0 ? (settings?.budget / 100).toFixed(2) : 0),
    [settings?.budget]
  );
  const perPersonBudget = useMemo(
    () =>
      groupOrders?.tax_info?.currency +
        groupOrders?.per_person_budget?.cents / 100 || 0,
    [groupOrders?.per_person_budget?.cents]
  );
  const orderDeadline = groupOrders?.order_deadline
    ? new Date(groupOrders.order_deadline * 1000)
    : null;

  const formattedDate = orderDeadline
    ? format(orderDeadline, "EEEE, MMMM do, h:mm a")
    : "loading...";

  const isToday =
    orderDeadline &&
    format(orderDeadline, "EEEE, MMMM do") ===
      format(new Date(), "EEEE, MMMM do");

  const managerData = [
    {
      id: 1,
      type: "Manager",
      iconSrc: "/img/top-up.png",
      title: "Total paid by company:",
      content: groupOrders?.tax_info?.currency + (consumed_budget / 100 || 0),
    },
    {
      id: 2,
      type: "Manager",
      iconSrc: "/img/floaticon1.svg",
      title: "Total paid by employees:",
      content:
        groupOrders?.tax_info?.currency + (totalPaidByEmployees / 100 || 0),
    },
    {
      id: 3,
      type: "Manager",
      iconSrc: "/img/floaticon3.svg",
      title: "Total orders:",
      content: groupOrders
        ? `${groupOrders?.joined_member_count} out of ${groupOrders?.head_count} joined`
        : "loading...",
    },
    {
      id: 4,
      type: "Manager",
      iconSrc: "/img/floaticon3.svg",
      title: "Total Estimated Cost:",
      content: `$${
        (groupOrders?.per_person_budget?.cents / 100 || 0) *
          groupOrders?.head_count || 0
      }`,
    },
    {
      id: 5,
      type: "Manager",
      iconSrc: "/img/floaticon2.svg",
      title: "Subsidy",
      content: `${groupOrders?.per_person_budget ? perPersonBudget : 0}/person`,
    },
  ];
  const userData = [
    {
      id: 6,
      type: "User",
      iconSrc: "/img/top-up.png",
      title: "Total Subsidy Spent:",
      content: groupOrders?.tax_info?.currency + (consumed_budget / 100 || 0),
    },
    {
      id: 7,
      type: "User",
      iconSrc: "/img/floaticon1.svg",
      title: "Subsidy:",
      content: groupOrders?.per_person_budget ? perPersonBudget : 0,
    },
    {
      id: 9,
      type: "User",
      iconSrc: "/img/floaticon3.svg",
      title: "Total Paid by You:",
      content: groupOrders?.tax_info?.currency + (totalPaidByEmployees || 0),
    },
  ];

  const defaultData = [
    {
      id: 11,
      type: "Default",
      iconSrc: "/img/top-up.png",
      title: "Top Up:",
      content: groupOrders?.tax_info?.currency + (total_topup / 100 || 0),
    },
    {
      id: 12,
      type: "Default",
      iconSrc: "/img/floaticon1.svg",
      title: "Budget Left:",
      content: groupOrders?.per_person_budget
        ? groupOrders?.tax_info?.currency + outsideFromBudget
        : "loading...",
    },
    {
      id: 13,
      type: "Default",
      iconSrc: "/img/floaticon2.svg",
      title: "Order must be submitted by:",
      content: (
        <>
          {isToday && <span>(TODAY) </span>}
          {formattedDate}
        </>
      ),
    },
    {
      id: 14,
      type: "Default",
      iconSrc: "/img/floaticon3.svg",
      title: "Group:",
      content: groupOrders
        ? `${groupOrders?.joined_member_count} out of ${groupOrders.head_count} joined`
        : "loading...",
    },
  ];

  return (
    <div className="invitation__block">
      <div className="container">
        <div
          className="invitation__head"
          style={{
            background: `URL("${groupOrders?.preset?.banner_image_url}") center center / cover no-repeat`,
          }}
        >
          <div className="invitation__box">
            <div className="welcome__invitation">
              <div className="welcome__date">
                <span>{presets?.name ? presets.name : "loading..."}</span>
              </div>
              <p>
                {presets?.short_address ? presets.short_address : "loading..."}
              </p>
            </div>
            <div className="invitation__desc">
              <span>{groupOrders?.name}</span>
              <p>
                {groupOrders?.delivery_date_time
                  ? format(
                      new Date(groupOrders.delivery_date_time * 1000),
                      "EEEE, MMMM do, h:mm aaa"
                    )
                  : "loading..."}
              </p>
            </div>
          </div>
          <a
            href={
              groupOrders?.preset?.shared_url
                ? groupOrders?.preset?.shared_url
                : "/"
            }
          >
            <ImagePreview
              image={groupOrders?.preset?.chefs[0]?.profile_image_url}
              alt="invititation"
            />
          </a>
        </div>
        {/** Manager View */}
        {hideEdit ? (
          isManager ? (
            <div className="orders__info">
              <div className="orders__left">
                {/*** Yohooooo */}
                {managerData.map((order, index) => (
                  <React.Fragment key={order.id}>
                    <OrderInformation
                      iconSrc={order.iconSrc}
                      title={order.title}
                      content={order.content}
                    />
                    {/* {index !== managerData.length - 1 && (
                        <div className="spacer" />
                      )} */}
                  </React.Fragment>
                ))}
              </div>
              <ShareIcon shareLink={shareLink} />
            </div>
          ) : (
            // UI for Regular Users when hideEdit is true "USER"
            <div className="orders__info">
              <div className="orders__left">
                {/*** Yohooooo */}
                {userData.map((order, index) => (
                  <React.Fragment key={order.id}>
                    <OrderInformation
                      iconSrc={order.iconSrc}
                      title={order.title}
                      content={order.content}
                    />
                  </React.Fragment>
                ))}
              </div>
              <div className="orders__right">
                <button type="button" onClick={shareLink}>
                  <span>
                    <img src="/img/shareicon.svg" alt="shareicon" />
                  </span>
                  Share link
                </button>
              </div>
            </div>
          )
        ) : (
          // UI when hideEdit is false (normal UI)
          <div className="orders__info">
            <div className="orders__left">
              {defaultData.map((order, index) => (
                <React.Fragment key={order.id}>
                  <OrderInformation
                    iconSrc={order.iconSrc}
                    title={order.title}
                    content={order.content}
                  />
                  {/* {index !== managerData.length - 1 && (
                        <div className="spacer" />
                      )} */}
                </React.Fragment>
              ))}
            </div>
            <div className="orders__right">
              <button type="button" onClick={shareLink}>
                <span>
                  <img src="/img/shareicon.svg" alt="shareicon" />
                </span>{" "}
                Share link
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Invitation;
