import React, { useEffect, useMemo, useRef } from "react";
import ProductCard from "../ProductCard";
import { useDispatch } from "react-redux";
import { MODAL_TOGGLE } from "../../../store/Modal/Modal.constant";
import { ORDER_REPEAT } from "../../../store/Products/Products.constant";
import { format } from "date-fns";

const OrderTeam = ({ image, refId, id, auth_id, user_id, currency, items, menus, budget, products, isJoin, hideBtn }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const changeRefId = () => {
        if (refId === id) {
            setTimeout(() => {
                window.scrollTo({
                    top: ref.current?.offsetTop + ref.current?.clientHeight,
                    behavior: 'smooth'
                });
            }, 1);
        }
    }
    useEffect(changeRefId, [refId])
    let order = [];
    let totalPrice = 0;
    menus.map(menu => {
        items.map(item => {
            if (menu.id === item.menu_id) {
                let userOrder = { ...menu, total_price_cents: item.sub_total, menu_items_mapping: { ...item.menu_item_mappings }, quantity: item.quantity }
                totalPrice += item.sub_total;
                order.push(userOrder)
            }
            return null;
        })
        return null;
    })
    const name = useMemo(() => {
        if (Array.isArray(items[0]?.first_name) && Array.isArray(items[0]?.last_name)) {
            return items[0]?.first_name[0]?.toUpperCase() + items[0]?.last_name[0]?.toUpperCase()
        }
        return ""
    }, [items[0]?.first_name, items[0]?.last_name]);

    return (
        <div ref={ref} className="team__order">
            <div className="team__info">
                <div className="team__person">
                    <div className="person__image">
                        {image
                            ? <img src={process.env.REACT_APP_API_URL + image} alt="order" />
                            : <div className='noPerson__image'>{name}</div>
                        }
                    </div>
                    <div className="person__info">
                        <p><span>
                            {auth_id !== items[0].group_order_member_id
                                ? items[0].first_name + ' ' + items[0].last_name + ' '
                                : 'You '
                            }
                            ordered on {format(new Date(items[0].updated_at * 1000), 'MM/dd/yyyy')} at {format(new Date(items[0].updated_at * 1000), 'h:mmaaa:')}</span></p>
                    </div>
                </div>
                <div className="team__orders--container">
                    {order.map((item, index) => <ProductCard disabledSelect={hideBtn} user_id={user_id} orderTeam={true} isJoin={isJoin} myProductsId={products?.myProductsId} oldMyOrderId={products.oldMyOrderId} key={index} product={item} />)}
                </div>
            </div>
            <div className="team__total">
                <span>Total</span>
                <p>{currency + (+totalPrice / 100).toFixed(2)}</p>
                {!hideBtn && (Array.from(new Map(items.map(item => [item.group_order_member_id, item])).values())?.map((item) => {
                    if (item?.group_order_member_id == id && item?.member_id !== auth_id) {
                        return (
                            <button type='button' onClick={() => {
                                dispatch({ type: ORDER_REPEAT, payload: { data: order, user_id: user_id } })
                                dispatch({ type: MODAL_TOGGLE, payload: { data: 'isModalReplaced', user_id: user_id } })
                            }}>Order the same</button>
                        )
                    }
                    return <div>Me</div>
                }))}
            </div>
        </div>
    )
}
export default OrderTeam