import React from "react";
const OrderInformation = ({ iconSrc, title, content }) => {
  return (
    <>
      {" "}
      <div className="elem__order">
        <div className="order__image">
          <img className="topUp" src={iconSrc} alt="floaticon" />
        </div>
        <div className="order__info">
          <span>{title}</span>
          <p>{content}</p>
        </div>
      </div>
    </>
  );
};
export default OrderInformation;
